export default [
    {
        "id":1,
        "nombre":"AUDIFONO  SONY N",
        "img":"1.jpg"
    },
    {
        "id":2,
        "nombre":"AUDIFONO SONY A",
        "img":"2.jpg"
    },
    {
        "id":3,
        "nombre":"AUDIFONOS HUAWEI",
        "img":"3.jpg"
    },
    {
        "id":4,
        "nombre":"AUDIFONO JBL",
        "img":"4.jpg"
    },
    {
        "id":5,
        "nombre":"AUDIFONO JBL SUPRA",
        "img":"5.jpg"
    },
    {
        "id":6,
        "nombre":"AUDIFONOS SKULLCANDY",
        "img":"6.jpg"
    },
    {
        "id":7,
        "nombre":"AUDIFONOS STF",
        "img":"7.jpg"
    },
    {
        "id":8,
        "nombre":"BILLBOARD BARRA DE SONIDO A",
        "img":"8.webp"
    },
    {
        "id":9,
        "nombre":"BILLBOARD BARRA DE SONIDO B",
        "img":"9.jpg"
    },
    {
        "id":10,
        "nombre":"BILLBOARD TEATRO EN CASA A",
        "img":"10.jpg"
    },
    {
        "id":11,
        "nombre":"BILLBOARD TEATRO EN CASA B",
        "img":"11.jpg"
    },
    {
        "id":12,
        "nombre":"BOCINA ALEXA A",
        "img":"alexa12131415.jpg"
    },
    {
        "id":13,
        "nombre":"BOCINA ALEXA B",
        "img":"alexa12131415.jpg"
    },
    {
        "id":14,
        "nombre":"BOCINA ALEXA C",
        "img":"alexa12131415.jpg"
    },
    {
        "id":15,
        "nombre":"BOCINA ALEXA D",
        "img":"alexa12131415.jpg"
    },
    {
        "id":16,
        "nombre":"BOCINA HOPESTAR",
        "img":"16.jpg"
    },
    {
        "id":17,
        "nombre":"BOCINA HOPESTAR MINI",
        "img":"17.jpg"
    },
    {
        "id":18,
        "nombre":"BOCINA INALAMBRICA A",
        "img":"18.jpg"
    },
    {
        "id":19,
        "nombre":"BOCINA INALAMBRICA B",
        "img":"18.jpg"
    },
    {
        "id":20,
        "nombre":"BOCINA LG MINI",
        "img":"20.jpg"
    },
    {
        "id":21,
        "nombre":"BOCINA LG XBOOM A",
        "img":"21.jpg"
    },
    {
        "id":22,
        "nombre":"BOCINA LG XBOOM B",
        "img":"22.jpg"
    },
    {
        "id":23,
        "nombre":"BOCINA STF LUZ LED",
        "img":"23.jpg"
    },
    {
        "id":24,
        "nombre":"DRINKOPOLY",
        "img":"24.jpg"
    },
    {
        "id":25,
        "nombre":"EARPHONES TRUE XIAOMI",
        "img":"25.jpg"
    },
    {
        "id":26,
        "nombre":"EARPHONES XIAOMI",
        "img":"26.jpg"
    },
    {
        "id":27,
        "nombre":"FREIDORA DE AIRE",
        "img":"27.jpg"
    },
    {
        "id":28,
        "nombre":"JUEGO DE MESA SALUD",
        "img":"28.jpg"
    },
    {
        "id":29,
        "nombre":"ECHO SHOW",
        "img":"29.jpg"
    },
    {
        "id":30,
        "nombre":"SMARTWATCH ONIX",
        "img":"30.jpg"
    },
    {
        "id":31,
        "nombre":"SMARTWATCH ROSA",
        "img":"31.jpg"
    },
    {
        "id":32,
        "nombre":"JENGA",
        "img":"32.jpg"
    },
    {
        "id":33,
        "nombre":"iPad",
        "img":"33.jpg"
    },
    {
        "id":34,
        "nombre":"Pantalla 65 PULGADAS",
        "img":"34.jpg"
    },
    {
        "id":35,
        "nombre":"Pantalla 55 PULGADAS",
        "img":"35.jpg"
    },
    {
        "id":36,
        "nombre":"BICICLETA KAIZER",
        "img":"36.jpg"
    },
    {
        "id":37,
        "nombre":"BICICLETA DE MONTAÑA",
        "img":"37.jpg"
    },
    {
        "id":38,
        "nombre":"BARRA DE SONIDO AA",
        "img":"38.jpg"
    },
    {
        "id":39,
        "nombre":"BARRA DE SONIDO BB",
        "img":"39.jpg"
    },
]